import { Link } from '$shared/components';
import { useFrame } from '$shared/utils';
import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { MiniBasketButton } from './components/MiniBasketButton';
import { FavoriteButton } from './components/FavoriteButton';
import {
    MenuHeaderContainer,
    StyledContent,
    StyledDesktopNav,
    StyledIconNav,
    StyledLogo,
    StyledLogoContainer,
    StyledMainNavigation,
    StyledSearchInputContainer,
    StyledSearchInputContainerMobile,
    StyledLeftContainer,
    StyledRightContainer,
    ProgressLoader,
} from './styled';
import { MobileMegaMenuButton } from './components/MobileMegaMenuButton';
import { N30MegaMenu } from '~/features/navigation/components/N30MegaMenu';
import { SearchInput } from '../search/SearchInput';
import { theme } from '~/theme';
import { DesktopMetaMenuItem } from './components/DesktopMetaMenuItem';
import { UspMetaMenuItem } from './components/UspMetaMenuItem';
import { LoginButton } from './components/LoginButton';
import { Banner } from '~/shared/components/Banner';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import styled from '@emotion/styled';
import { mq } from '~/lib';

import { usePage } from '~/templates/pages/hooks/usePage';
import Flex from '~/shared/components/Flex';
import { useMenuScroll } from '~/templates/pages/hooks/useMenuScroll';
import { useBannerState } from '../../hooks/useBannerState';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import dynamic from 'next/dynamic';
import { useSearchStore } from '~/services/search/useSearchStore';
import { InstantSearch } from 'react-instantsearch';
import { algoliaClient, getAlgoliaIndex } from '~/lib/algolia';
import FlexContent from '~/shared/components/FlexGrowContent';
import { useRouter } from 'next/router';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';

const indexName = getAlgoliaIndex('MAIN');

type NavJustifyContent = {
    center: string;
    end: string;
    start: string;
};
type Props = {
    showTopBanner: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SearchOverlay = dynamic(() => import('../search/SearchOverlay'), {
    ssr: false,
});

const N20MainMenuComponent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const isMobile = useIsMobile();
    const { menuType } = useMenuScroll();
    const { showTopBanner } = props;
    const { push } = useRouter();
    const {
        data,
        data: { settings },
    } = useFrame();
    const { isStorePersonnel } = useCustomer();
    const { showSearchPage, setShowSearch } = useSearchStore();
    const page = usePage();
    const { mainMenu = [] } = data?.navigation || {};
    const headerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => headerRef.current as HTMLDivElement, [ref]);
    const justify: NavJustifyContent = {
        center: 'center',
        end: 'flex-end',
        start: 'flex-start',
    };

    const searchOverlayRef = useRef<HTMLDivElement>(null);
    const menuHeaderRef = useRef<HTMLDivElement>(null);
    const headerNavigationRef = useRef<HTMLDivElement>(null);

    const { setIsBannerOpen, isBannerOpen } = useBannerState();

    const closeBanner = () => {
        setIsBannerOpen(false);
    };

    const goToSearchQuery = (searchQuery: string) => {
        const searchPageUrl = settings?.pageReferences.searchPage?.url ?? '/';

        // Evaluate URL if it already has a slash at the beginning to prevent routing errors.
        const evaluatedUrl = searchPageUrl.charAt(0) === '/' ? searchPageUrl : `/${searchPageUrl}`;

        push(`${evaluatedUrl}?query=${searchQuery}`);

        setShowSearch(false);
    };

    const redirectToPage = (redirectUrl: string) => {
        push(redirectUrl);
        setShowSearch(false);
    };

    const handleSubmitSearch = async (searchQuery: string, redirectUrl?: string) => {
        const siteRoot = window?.location?.origin;

        if (!redirectUrl) {
            goToSearchQuery(searchQuery);
            return;
        }

        if (redirectUrl && !redirectUrl.startsWith('/')) {
            redirectToPage(redirectUrl);
            return;
        }

        try {
            const res = await fetch(`${siteRoot}${redirectUrl}`, { method: 'HEAD' });
            if (res.status === 200) {
                redirectToPage(redirectUrl);
                return;
            } else {
                goToSearchQuery(searchQuery);
                return;
            }
        } catch (error) {
            goToSearchQuery(searchQuery);
        }
    };

    return useMemo(
        () => (
            <StyledMainNavigation
                key={'mainMenu'}
                variants={{
                    Full: {
                        y: 0,
                        transition: {
                            delayChildren: 1,
                            duration: 0.1,
                            ease: 'easeIn',
                        },
                        transitionEnd: {
                            y: 0,
                        },
                    },
                    Hidden: {
                        y: 'calc(-100% + 4px)',
                        transition: {
                            delayChildren: 1,
                            duration: 0.1,
                            ease: 'easeIn',
                        },
                    },
                    Desktop: {
                        y: 0,
                        transition: {
                            delayChildren: 1,
                            duration: 0.1,
                            ease: 'easeIn',
                        },
                    },
                }}
                initial={menuType}
                animate={menuType}
                ref={headerRef}
            >
                {showTopBanner === true && (
                    <Banner
                        textColor={('#' + data.header?.topBannerTextColor) as string}
                        backGroundColor={('#' + data.header?.topBannerBackGroundColor) as string}
                        bannerText={data.header?.topBanner as string}
                        variant="top"
                    />
                )}
                <StyledContent
                    css={{
                        [mq(0, 'md')]: {
                            display: 'none',
                        },
                    }}
                    topBanner
                >
                    {!!data.navigation?.metaMenu?.UspLinks?.length && (
                        <Banner
                            textColor={'#' + data.header?.uspTextColor}
                            backGroundColor={
                                isStorePersonnel
                                    ? theme.colors.brandOrangeMedium
                                    : '#' + data.header?.uspBackgroundColor
                            }
                            childComponent={
                                <>
                                    {data.navigation?.metaMenu?.UspLinks?.map((section) => {
                                        return (
                                            <UspMetaMenuItem
                                                section={section}
                                                desktop
                                                textColor={
                                                    ('#' + data.header?.uspTextColor) as string
                                                }
                                                key={section.id}
                                            />
                                        );
                                    })}
                                </>
                            }
                            desktop
                        />
                    )}
                    {data.header?.campaignBannerLink && (
                        <Banner
                            textColor={('#' + data.header?.textColor) as string}
                            backGroundColor={
                                (isStorePersonnel
                                    ? theme.colors.brandOrangeLight
                                    : '#' + data.header?.backGroundColor) as string
                            }
                            bannerText={data.header?.campaignBannerLink?.title}
                            bannerLink={data.header?.campaignBannerLink}
                            withCloseBanner
                            desktop
                            variant="campaign"
                        />
                    )}
                </StyledContent>

                <MenuHeaderContainer ref={menuHeaderRef}>
                    <StyledLeftContainer>
                        <StyledIconNav mobile justifyContent={justify.start}>
                            <MobileMegaMenuButton />
                            <LoginButton textColor={theme.colors.black} desktop metaMenu />
                        </StyledIconNav>
                        {!isMobile && (
                            <StyledIconNav notMobile={true} justifyContent={'flex-start'}>
                                <StyledSearchInputContainer
                                    activeOverlay={showSearchPage}
                                    notMobile={true}
                                    data-testid="search-input-container"
                                >
                                    <InstantSearch
                                        searchClient={algoliaClient}
                                        indexName={indexName}
                                    >
                                        {showSearchPage && (
                                            <SearchOverlay
                                                searchOverlayRef={searchOverlayRef}
                                                headerRef={headerRef}
                                                menuHeaderRef={menuHeaderRef}
                                                headerNavigationRef={headerNavigationRef}
                                                onSubmitSearch={handleSubmitSearch}
                                            />
                                        )}

                                        <SearchInput onSubmitSearch={handleSubmitSearch} />
                                    </InstantSearch>
                                </StyledSearchInputContainer>
                            </StyledIconNav>
                        )}
                    </StyledLeftContainer>
                    <FlexContent ref={searchOverlayRef} />

                    <StyledLogoContainer activeOverlay={showSearchPage}>
                        <Link aria-label="Imerco" href="/">
                            <StyledLogo />
                        </Link>
                    </StyledLogoContainer>
                    <StyledRightContainer>
                        <StyledIconNav data-testid="top-icon-nav" justifyContent={justify.end}>
                            <Flex
                                css={{
                                    [mq(0, 'md')]: {
                                        display: 'none',
                                    },
                                }}
                            >
                                {data.navigation?.metaMenu?.DesktopLinks?.map((section) => {
                                    return (
                                        <DesktopMetaMenuItem
                                            key={section.id}
                                            section={section}
                                            textColor={theme.colors.black}
                                            desktop
                                            metaMenu
                                        />
                                    );
                                })}
                            </Flex>

                            <LoginButtonT textColor={theme.colors.black} desktop metaMenu />
                            <FavoriteButton textColor={theme.colors.black} desktop metaMenu />

                            <MiniBasketButton textColor={theme.colors.black} desktop metaMenu />
                        </StyledIconNav>
                    </StyledRightContainer>
                </MenuHeaderContainer>
                {isMobile && (
                    <StyledSearchInputContainerMobile
                        notMobile={false}
                        activeOverlay={showSearchPage}
                        data-testid="search-input-container"
                    >
                        <InstantSearch searchClient={algoliaClient} indexName={indexName}>
                            <SearchInput onSubmitSearch={handleSubmitSearch} />
                            {showSearchPage && (
                                <SearchOverlay
                                    searchOverlayRef={searchOverlayRef}
                                    headerRef={headerRef}
                                    menuHeaderRef={menuHeaderRef}
                                    headerNavigationRef={headerNavigationRef}
                                    onSubmitSearch={handleSubmitSearch}
                                />
                            )}
                        </InstantSearch>
                    </StyledSearchInputContainerMobile>
                )}
                <StyledDesktopNav ref={headerNavigationRef} data-testid={'main-menu'}>
                    {headerRef && <N30MegaMenu navigation={mainMenu} headerRef={headerRef} />}
                </StyledDesktopNav>

                {isBannerOpen === true && data.header?.campaignBannerLink && (
                    <>
                        <Banner
                            css={{
                                [mq('md')]: {
                                    display: 'none',
                                },
                            }}
                            onCloseBanner={closeBanner}
                            textColor={'#' + data.header?.textColor}
                            backGroundColor={'#' + data.header?.backGroundColor}
                            bannerText={data.header?.campaignBannerLink?.title}
                            bannerLink={data.header?.campaignBannerLink}
                            withCloseBanner
                            variant="campaign"
                        />
                    </>
                )}
                <ProgressLoader id="progress-loader" />
            </StyledMainNavigation>
        ),
        [menuType, isBannerOpen, page?.type, showSearchPage, isMobile],
    );
});

const LoginButtonT = styled(LoginButton)(() => ({
    [mq(0, 'md')]: {
        display: 'none',
    },
}));

export const N20MainMenu = withErrorBoundary(N20MainMenuComponent);
