import Search from '$icons/search.svg';
import ArrowLeft from '$icons/arrow-left.svg';
import CloseIcon from '$icons/close.svg';
import React, { useEffect, useRef, useState, FC } from 'react';
import {
    SearchPlaceholder,
    SearchPlaceholderIconContainer,
    SearchPlaceholderTextContainer,
    StyledSearchCloseButton,
    StyledSearchInputDesktop,
} from '../N20MainMenu/styled';
import { useTranslation } from '~/shared/utils/translation';
import { useSearchStore } from '~/services/search/useSearchStore';
import { useRouter } from 'next/router';
import { useDebounce, useKeyPressEvent } from 'react-use';
import { useHits, useSearchBox, UseSearchBoxProps, useConfigure } from 'react-instantsearch';

type ISearchBoxProps = UseSearchBoxProps & {
    onSubmitSearch: (searchQuery: string, redirectUrl?: string) => void;
};

type SearchBoxProps = {
    value: string;
    clearInput: () => void;
    onSubmitSearch: (searchQuery: string, redirectUrl?: string) => void;
    handleClose: () => void;
};

type IconProps = {
    name: 'search' | 'arrowLeft' | 'close';
};

type SearchPlaceholderProps = {
    icon: 'search' | 'arrowLeft' | 'close';
    children?: React.ReactNode;
    onClick?: () => void;
};

const queryHook: UseSearchBoxProps['queryHook'] = (query, search) => {
    if (!query || query.length < 3) {
        return false;
    }

    search(query);
};

const SearchBox = ({ value, clearInput, onSubmitSearch, handleClose }: SearchBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState(value);
    const { translate } = useTranslation();

    useConfigure({ hitsPerPage: 6 });
    const { hits, results } = useHits();
    const { refine, clear } = useSearchBox({
        queryHook,
    });

    useEffect(() => inputRef?.current?.focus(), []);

    useKeyPressEvent('Escape', () => {
        clear();
        handleClose();
    });

    useKeyPressEvent('Enter', () => {
        if (!query) {
            return;
        }

        if (inputRef.current === document.activeElement) {
            if (hits.length) {
                inputRef?.current?.blur();
                clear();
                onSubmitSearch(query, (results as any)?.renderingContent?.redirect?.url);
            }
        }
    });

    useDebounce(
        () => {
            refine(query);
        },
        300,
        [query],
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        if (!query) {
            clearInput();
            return;
        }

        setQuery(query);
    };

    return (
        <>
            <SearchPlaceholderComponent icon="arrowLeft" onClick={handleClose}>
                {!value && translate('header.search')}
            </SearchPlaceholderComponent>
            <StyledSearchInputDesktop
                defaultValue={value}
                onChange={handleSearch}
                withLabel={false}
                ref={inputRef}
                data-testid="search-input"
            />
            <StyledSearchCloseButton size="small" onClick={clearInput} variant="plain">
                <CloseIcon />
            </StyledSearchCloseButton>
        </>
    );
};

const Icon: FC<IconProps> = ({ name }) => {
    const icons = {
        search: Search,
        arrowLeft: ArrowLeft,
        close: CloseIcon,
    };

    const SelectedIcon = icons[name];
    return <SelectedIcon />;
};

const SearchPlaceholderComponent: FC<SearchPlaceholderProps> = ({ icon, children, onClick }) => (
    <SearchPlaceholder onClick={onClick}>
        <SearchPlaceholderIconContainer>
            <Icon name={icon} />
        </SearchPlaceholderIconContainer>
        <SearchPlaceholderTextContainer>{children}</SearchPlaceholderTextContainer>
    </SearchPlaceholder>
);

export const SearchInput: FC<ISearchBoxProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { query: params } = useRouter();
    const [value, setValue] = useState('');
    const { translate } = useTranslation();
    const { setShowSearch, showSearchPage } = useSearchStore();

    useKeyPressEvent('Escape', () => {
        if (inputRef.current === document.activeElement) {
            handleClose();
        }
    });

    useKeyPressEvent('Tab', () => {
        if (inputRef.current === document.activeElement) {
            handleClose();
        }
    });

    useEffect(() => {
        if (!showSearchPage) {
            setValue('');
            setShowSearch(false);
        }
    }, [showSearchPage]);

    const handleClearInput = () => {
        setValue('');
        setTimeout(() => {
            inputRef?.current?.focus();
        });
    };

    const handleClose = () => {
        setShowSearch(false);
        setTimeout(() => {
            inputRef?.current?.blur();
        });
    };

    const handleFocus = () => {
        setShowSearch(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    if (value) {
        return (
            <SearchBox
                value={value}
                clearInput={handleClearInput}
                onSubmitSearch={props.onSubmitSearch}
                handleClose={handleClose}
            />
        );
    }

    return (
        <>
            <SearchPlaceholderComponent
                icon={showSearchPage ? 'arrowLeft' : 'search'}
                onClick={handleClose}
            >
                {!showSearchPage && translate('header.search')}
            </SearchPlaceholderComponent>

            <StyledSearchInputDesktop
                aria-label="Search"
                value={value}
                defaultValue={params.query}
                onFocus={handleFocus}
                onChange={handleChange}
                withLabel={false}
                ref={inputRef}
                data-testid="search-input"
            />
        </>
    );
};
